<template>
  <div class="main_container">
    <div class="col-12 bread-crumbs">
      <router-link v-if="user[1] != 'DGIR'"
                   to="/Marche"> Marché ></router-link>
      <router-link v-else
                   to="/Marche"> Lettre de commande</router-link>
      <router-link :to="`/Marche/detail/${this.$route.params.id}`">
        Marché n°{{$route.params.id}}
      </router-link>
      <span> > Liste des accords de financement</span>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Liste des accords de financement
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row">
      <button class="btn btn-blue pl-5 pr-5 op-btn"
              v-if="(user[1] == 'FER' && user[0] == 'DT')||(user[1] == 'FER' && user[0] == 'ADMIN')"
              data-toggle="modal"
              ref="modal_button"
              data-target="#exampleModal"
              @click="av='accord'">Ajouter un accord de financement</button>
    </div>
    <div class="inner_container">
      <!--Tableau avenant  -->
      <div class="row mt-3">
        <table class="table tablestyle_3 table-striped">
          <thead>
            <tr>
              <th class="th-blue">Références</th>
              <th class="th-blue">Description</th>
              <th class="th-blue">Date de l'accord</th>
              <th class="th-blue">Numéro du marché</th>
              <th class="th-blue">Montant</th>
              <th class="th-blue text-center">Action</th>
            </tr>
          </thead>
          <tbody v-if="long==true && longueur!=0">
            <tr v-for="(accord,akey) in listAccordsFinancement.donnees"
                :key="akey">
              <td scope="row">{{accord.ref_accord_financement}}</td>
              <td class="text-left">{{accord.description}}</td>
              <td>{{accord.date_accord_financement}}</td>
              <td>{{accord.marche.numero_marche}}</td>
              <td class="text-right">{{accord.montant_accord_financement.toLocaleString()}}</td>
              <td class="icon-blue text-right">
                <i class="flaticon-pencil"  
                   v-if="(user[1] == 'FER' && user[0] == 'DT')||(user[1] == 'FER' && user[0] == 'ADMIN')"
                   @click="updateForm(accord),av='modifaccord'"
                   ref="modal_button"
                   data-toggle="modal"
                   data-target="#exampleModal"></i>
                <i class="flaticon-delete ml-4"  
                   v-if="(user[1] == 'FER' && user[0] == 'DT')||(user[1] == 'FER' && user[0] == 'ADMIN')"
                   @click="av='delaccord',form.id=accord.id"
                   data-toggle="modal"
                   data-target="#exampleModal"></i>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="long==true && longueur==0">
            <tr>
              <td scope="row"
                  colspan="6"
                  class="text-center"> Aucune donnée disponible...</td>
            </tr>
          </tbody>
          <tbody v-else-if="long==false">
            <tr>
              <td scope="row"
                  colspan="6"
                  class="text-center">Chargement en cours...</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="exampleModalLabel"
                v-if="av=='accord'">Ajouter un accord de financement</h5>
            <h5 class="modal-title"
                id="exampleModalLabel"
                v-if="av=='modifaccord'">Détail accord de financement</h5>
            <h5 class="modal-title"
                id="exampleModalLabel"
                v-if="av=='delaccord'">Supprimer un accord de financement</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="modAccord='',av=''">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body"
               v-if="av=='accord'">
            <CreateAccord :id_marche="$route.params.id"
                          @accord="added => (code = added)"/>
          </div>
          <div class="modal-body"
               v-if="av=='modifaccord'">
            <UpdateAccord :id_marche="$route.params.id"
                          :mod_accord="modAccord"
                          @upAccord="updated => (code = updated)"/>
          </div>
          <div class="modal-body"
               v-if="av=='delaccord'">
            Voulez-vous vraiment supprimer cet accord de financement?
          </div>
          <div class="modal-footer"
               v-if="av=='delaccord'">
            <button type="button"
                    class="btn btn-secondary"
                    @click="destroyAccord()">Oui</button>
            <button type="button"
                    class="btn btn-primary"
                    data-dismiss="modal">Non</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
  .table-striped tbody td,
  .table-striped thead th{
    font-size: 0.48em;
  }
</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import Notif from "@/components/shared/Toast"
import CreateAccord from "./Add.vue"
import UpdateAccord from "./Update.vue"
import form from 'vuejs-form'

export default {
  name:"AccordsFinancement",
  components:{
    Notif,
    CreateAccord,
    UpdateAccord
  },
  data: ()=>({
    av:"accord",
    modAccord:"",
    code:false,
    user:"",
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    form: form({
      id_marche:"",
      id:""
    }).rules({
      id_marche:"required",
      id:"required"
    }),
    long:false,
    longueur:"",
  }),
  watch:{
    listAccordsFinancement(){
      if (this.listAccordsFinancement) {
        this.long=true
        this.longueur=this.listAccordsFinancement.donnees.length
      }
    },
    code(){
      if (this.code) {
        if (this.av=="accord") {
          this.notif.message = "Accord de financement créé avec succès."
        }else{
          if (this.av=="modifaccord") {
            this.notif.message = "Accord de financement modifié avec succès."
          }
        }
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.long=false
            this.longueur=0
            this.setListAccordsFinancement("")
            this.getListAccordsFinancement({id:this.$route.params.id})
            this.$refs["btn-close"].click()
          }.bind(this),
          3000
        ) 
      }
    },
    msgFailAccordsFinancement(){
      if (this.msgFailAccordsFinancement) {
        switch (this.av) {
          case "accord":
            this.notif.message = "Création d'accord de financement échouée."
            break
          case "modifaccord":
            this.notif.message = "Modification d'accord de financement échouée."
            break
          default:
            this.notif.message = "Suppression d'accord de financement échouée."
            break
        }
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMsgFailAccordsFinancement("")
            this.$refs["btn-close"].click()
          }.bind(this),
          3000
        ) 
      }
    }
  },
  created(){
    this.getListAccordsFinancement({id:this.$route.params.id})
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
  },
  computed:{
    ...mapGetters(["listAccordsFinancement",'msgFailAccordsFinancement','msgSuccessAccordsFinancement'])
  },
  methods:{
    ...mapActions(["getListAccordsFinancement","deleteAccordsFinancement"]),
    ...mapMutations(["setListAccordsFinancement","setMsgFailAccordsFinancement"]),
    updateForm(accord){
      this.modAccord=''
      this.modAccord=accord
    },
    destroyAccord(){
      this.form.id_marche=this.$route.params.id
      // console.log(this.form.data)
      this.deleteAccordsFinancement(this.form.data)
    }
  }
}
</script>