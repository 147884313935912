<template>
  <div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <form class="form_default">
      <div class="form-group">
        <label  class="req">Référence de l'accord</label>
        <input
          type="text"
          name="libelle"
          v-model='form.ref_accord_financement'
          class="form-control form-control-lg"
          aria-label=".form-control-lg example"
          autocomplete="off">
        <span
          v-if="form.errors().has('libelle')"
          v-text="form.errors().get('libelle')"
          class="errorMsg">
        </span>
      </div>
      <div class="form-row row">
        <div class="form-group">
          <label>Référence de l'accord (Fichier)</label>
          <div class="custom-file">
            <label class="custom-file-label">
              {{ inputFiles.ref_accord_financement_fichier }}
            </label>
            <input
              type="file"
              name="ref_accord_financement_fichier"
              id="ref_accord_financement_fichier"
              ref="ref_accord_financement_fichier"
              class="custom-file-input"
              placeholder="jpg jpeg png pdf xlsx csv doc"
              accept=".jpg,.jpeg,.png,.pdf,.xlsx"
              v-on:click="removeSPan('ref_accord_financement_fichier')"
              v-on:change="handleFileUpload('ref_accord_financement_fichier')"
            />
            <span class="listExtension">
              Format :
              <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
            </span>
            <span v-if="form.errors().has('ref_accord_financement_fichier') && affiche.ref_accord_financement_fichier !==   'ref_accord_financement_fichier'"
                  v-text="form.errors().get('ref_accord_financement_fichier')"
                  class="errorMsg"> </span>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="req">Montant de l'accord</label>
        <input
          type="text"
          name="libelle"
          @input="addSpace('montant_accord_financement')"
          v-model='montant_accord_financement'
          class="form-control form-control-lg"
          aria-label=".form-control-lg example"
          autocomplete="off">
        <span
          v-if="form.errors().has('libelle')"
          v-text="form.errors().get('libelle')"
          class="errorMsg">
        </span>
      </div>
      <div class="form-group">
        <label  class="req">Date de l'accord</label>
        <input
          type="date"
          name="libelle"
          v-model='form.date_accord_financement'
          class="form-control form-control-lg"
          aria-label=".form-control-lg example"
        >
        <span
          v-if="form.errors().has('libelle')"
          v-text="form.errors().get('libelle')"
          class="errorMsg">
        </span>
      </div>
      <div class="form-group">
        <label  class="req">Description</label>
        <textarea class="form-control" 
                  name="" 
                  id="" 
                  rows="3"
                  v-model="form.description"></textarea>
        <span
          v-if="form.errors().has('libelle')"
          v-text="form.errors().get('libelle')"
          class="errorMsg">
        </span>
      </div>
      <div class="button_form">
        <button
          type="button"
          class="btn btn-f-blue enter_btn"
          @click='submit'>
          <i class="flaticon-floppy-disk"></i> Modifier
        </button>
        <button
          type="reset"
          class="btn btn-f-blue cancle_btn ml-4">
          <i class="flaticon-cancel"></i> Annuler
        </button>
      </div>
    </form>
  </div>
</template>
<style>
.form_default input[type="text"],
.form_default input[type="date"],
.form_default input[type="number"],
.form_default input[type="file"],
.form_default textarea,
.form-group label,
.form_default select,
.form_default button{
  font-size: 0.6em;
}
.form_default input{
  height: fit-content;
}
</style>
<script>
import form from 'vuejs-form'
import utils from "@/assets/js/utils"
import Notif from "@/components/shared/Toast"

import {mapActions,mapGetters} from "vuex"
export default {
  name: "UpdateAccord",
  components:{
    Notif
  },
  props:["id_marche","mod_accord"],
  data: () => ({
    inputFiles:{
      ref_accord_financement_fichier:""
    },
    affiche:{
      ref_accord_financement_fichier:""
    },
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    listExtensionFiles:"",
    montant_accord_financement: "",   
    uploadfile:"",
    form: form({
      id_marche:"",
      ref_accord_financement: "",
      ref_accord_financement_fichier:"",
      montant_accord_financement: "",   
      date_accord_financement: "",
      description: "", 
    })
  }),
  watch: {
    // ['form.data']: {
    //   deep: true,
    //   immediate: false,
    //   handler: 'onFormChange'
    // },
    msgSuccessAccordsFinancement(){
      if (this.msgSuccessAccordsFinancement) {
        this.$emit("upAccord",{updated:true})
      }
    },
    files() {
      if (this.files && this.files["url"]) {
        this.form[this.uploadfile] = process.env.VUE_APP_UPLOAD + this.files["url"]
        this.loadrarc = false
        this.$refs["ref_accord_financement_fichier"].disabled = false
      }
    },
  },
  created(){
    this.listExtensionFiles = utils.formaFichierAccepte
    this.form.id=this.mod_accord.id
    this.form.id_marche=this.id_marche
    this.form.ref_accord_financement= this.mod_accord.ref_accord_financement
    this.form.ref_accord_financement_fichier= this.mod_accord.ref_accord_financement_fichier
    this.inputFiles.ref_accord_financement_fichier= this.mod_accord.ref_accord_financement_fichier.split("/")
    this.inputFiles.ref_accord_financement_fichier= this.inputFiles.ref_accord_financement_fichier[5]
    this.form.montant_accord_financement= this.mod_accord.montant_accord_financement  
    this.montant_accord_financement= this.mod_accord.montant_accord_financement.toLocaleString()  
    this.form.date_accord_financement= this.mod_accord.date_accord_financement
    this.form.description= this.mod_accord.description
  },
  computed: {
    ...mapGetters(['msgFailAccordsFinancement','msgSuccessAccordsFinancement',"files"])
  },
  methods: {
    ...mapActions(["updateAccordsFinancement","saveFile"]),
    // onFormChange() {
    //   this.form.validate()
    // },
    submit() {
      console.log(this.form.data)
      this.form.id_marche=this.id_marche
      if (!this.form.validate().errors().any()) {
        this.updateAccordsFinancement(this.form.data)
      }
    },
    /**
     * Creation des espaces pour les extentions de fichiers
     * @returns {string}
     * @constructor
     */
    CreateEspaceBetweenFileExtentions() {
      return utils.formaFichierAccepteToString()
    },
    handleFileUpload(filename) {
      // initialisation de la variable de vérification du nom
      var oldName = this.inputFiles[filename]
      if (this.$refs[filename].files[0]) {
        //condition d'existance d'un nouveau fichier
        if (this.$refs[filename].files[0].name != oldName) {
          // vérification de la concordance des noms
          this.loadrarc = true
        }
        this.inputFiles[filename] = this.$refs[filename].files[0].name
        if (!this.inputFiles[filename].includes(" ")) {
          var extension = this.inputFiles[filename].split(".")[1]
          if (this.listExtensionFiles.includes(extension)) {
            this.uploadfile = filename
            this.saveFile(this.$refs[filename].files[0])
          } else {
            this.loadrarc = false
            this.loadraf = false
            this.$refs["reference_af"].disabled = false
            this.$refs["reference_arc"].disabled = false
            this.inputFiles[filename] = ""

            this.notif.message = "Mauvais Format du fichier"
            this.notif.type = "toast-danger"
            this.notif.activated = "activated"
            setTimeout(
              function() {
                this.notif.activated = ""
                this.setErors("")
              }.bind(this),
              5000
            )
          }  
        }else{
          this.notif.message = "Vérifiez qu'il n'y a pas d'espaces dans le nom du fichier"
          this.notif.type = "toast-danger"
          this.notif.activated = "activated"
          this.loadrarc = false

          setTimeout(
            function() {
              this.notif.activated = ""
              this.$refs[filename].value=""
              this.inputFiles[filename]=""
            }.bind(this),
            3000
          )
        }
        
      }
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this[key].split("")
      var res =""
      this.form[key]=""
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
            splinter[index] = ""
          }
          chaine += splinter[index]
        }
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      this[key] = chaine
      res = this[key].split(" ")
      for (let index = 0; index < res.length; index++) {
        this.form[key] += res[index]
      }
      this.form[key]=Number(this.form[key])
    },
    removeSPan(element) {
      for (let afficheKey in this.affiche) {
        const valeur = this.affiche[afficheKey].split(":")[0]
        if (valeur === element) {
          this.affiche[afficheKey] = valeur
        }
      }
    },
  }
}
</script>
